@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Medium.ttf);
}

* {
  font-family: "DM Sans";
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 18px;
}

p {
  line-height: 1.7 !important;
}

.widget {
  position: fixed;
  bottom: 10%;
  right: 3%;
}

.widget img {
  object-fit: cover;
  width: 50px;
  height: 50px;
}


.jumbotron {
  transition: background-image 0.5s ease-in-out;
}


.fade {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}